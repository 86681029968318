export const mockAlerts = [
  {
    key: "1",
    resident: "Tisher, Ramsey",
    difference: 35,
    found: "3/2/2020",
    ipaAlerts: [
      "Primary Diagnosis Change",
      "PT/OT Functional Score Change",
      "PT/OT Clinical Category Change",
      "SLP Clinical Category Change",
      "SLP Comorbidities Change",
      "SLP Cognitive Impairement Change",
      "SLP Swallowing Disorder Change",
      "SLP Mechanically Altered Diet Change",
      "NTA Score Change",
      "Nursing Functional Score Change",
      "Nursing Group Change",
    ],
    sigChangeAlerts: [
      "Non-Hospice > Hospice",
      "Hospice > Non-Hospice",
      "5% Weight Increase Last 30 Days",
      "5% Weight Decrease Last 30 Days",
      "10% Weight Increase Last 6 Months",
      "10% Weight Decrease Last 6 Months",
      "Decision Making Improvement",
      "Decision Making Decline",
      "Presense of Resident Mood Items Not Previously Reported",
      "ADL Decline",
      "ADL Improvement",
      "Increased Incontinence Pattern",
      "Decreased Incontinence Pattern",
      "New Pressure Ulcer",
      "Restraints Used",
    ],
    percent: 88,
  },
  {
    key: "2",
    resident: "Smith, John",
    difference: 25,
    found: "3/2/2020",
    ipaAlerts: ["SLP Comorbidities Change", "Nursing Functional Score Change"],
    sigChangeAlerts: [
      "10% Weight Decrease Last 6 Months",
      "Decision Making Improvement",
      "ADL Improvement",
    ],
    percent: 67,
  },
  {
    key: "3",
    resident: "Miller, Robin",
    difference: 85,
    found: "3/1/2020",
    ipaAlerts: ["Primary Diagnosis Change"],
    sigChangeAlerts: ["Hospice > Non-Hospice", "ADL Improvement"],
    percent: 60,
  },
  {
    key: "4",
    resident: "Smith, John",
    difference: 75,
    found: "2/29/2020",
    ipaAlerts: ["NTA Score Change"],
    sigChangeAlerts: [
      "10% Weight Decrease Last 6 Months",
      "ADL Improvement",
    ],
    percent: 52,
  },
  {
    key: "5",
    resident: "Abner, Abby",
    difference: 5,
    found: "2/28/2020",
    ipaAlerts: ["PT/OT Functional Score Change"],
    sigChangeAlerts: [
      "ADL Decline",
      "Decision Making Decline",
      "Increased Incontinence Pattern",
      "5% Weight Increase Last 30 Days",
    ],
    percent: 34,
  },
]
